/* stylelint-disable */
.popin-alert[id$=_popin] {
  position: fixed;
  z-index: var(--z-popup);
  background-color: #fff;
  width: 90%;
  padding: 2rem;
  border-radius: var(--radius-main);
  max-width: 600px;
}
.popin-alert[id$=_popin] p {
  margin-block: 1em;
  letter-spacing: 0.4px;
}
.popin-alert[id$=_popin] .popin-close {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  color: var(--color-neutral-1100);
  right: 1rem;
  top: 1rem;
}
.popin-alert[id$=_popin] + .popin-cache,
.popin-alert[id$=_popin] .full-cache {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  z-index: calc(var(--z-popup) - 1);
  background-color: #000;
  top: 0;
  left: 0;
  opacity: 0.7;
  filter: alpha(opacity=70);
}

[id=ask_popin] {
  top: 25%;
  left: 0;
  margin-left: 50%;
  transform: translateX(-50%);
  min-height: 300px;
}
@media (min-width: 768px) {
  [id=ask_popin] {
    width: 480px;
  }
}
[id=ask_popin] p.big {
  margin-bottom: 0.5rem;
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--color-brand-blue);
}
[id=ask_popin] p.big + p {
  font-size: 0.8rem;
}
[id=ask_popin] em.error {
  color: var(--color-coral-700);
  font-style: normal;
  text-transform: uppercase;
  font-size: 0.8rem;
}
[id=ask_popin] .form-control {
  height: 52px;
  border-radius: 3px;
  padding-inline: 20px;
  margin: 1rem 0 0;
  width: 100%;
  border: solid 1px var(--form-color-border);
}
[id=ask_popin] .form-control.error {
  border-color: var(--color-coral-700);
}
[id=ask_popin] .btn-primary {
  display: block;
  margin: 1rem auto 0;
  width: 30%;
}

[id=exit_popin] {
  top: 45%;
  margin: 0 auto;
  left: 0;
  right: 0;
  min-height: auto;
}
[id=exit_popin] .popin-content {
  padding: 2rem;
}
[id=exit_popin] .btn-primary {
  left: 5%;
}
@media (max-width: 767px) {
  [id=exit_popin] .btn-primary {
    width: 100%;
    left: 0;
  }
}
[id=exit_popin] .btn-secondary {
  left: 6%;
  --cta-bg: #fff;
  --cta-color: var(--color-blue-1100);
  --cta-bg-hover: var(--color-neutral-300);
  --cta-bg-active: #e3e6e9;
  --cta-bg-disabled: var(--color-neutral-200);
  --cta-color-disabled: #969da4;
  --cta-color-border: var(--color-neutral-600);
  --cta-color-border-disabled: var(--cta-color-disabled);
  border: 1px solid var(--cta-color-border);
}
@media (max-width: 767px) {
  [id=exit_popin] .btn-secondary {
    margin-top: 1rem;
    width: 100%;
    left: 0;
  }
}

#vision-mention {
  padding: 1rem 1rem 4rem;
  background-color: #fff;
}
#vision-mention p {
  margin-block: 1em;
}
#vision-mention .row {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}
#vision-mention .kol-sm-6 {
  width: 100%;
}
@media (min-width: 768px) {
  #vision-mention .kol-sm-6 {
    width: calc(50% - 1rem);
  }
}
#vision-mention .kol-sm-10.kol-sm-offset-1 {
  width: 100%;
  display: flex;
  justify-content: center;
}
#vision-mention .txt-center {
  text-align: center;
}
#vision-mention .h2 {
  --dash-color: var(--color-blue-800);
  --title-color: var(--color-neutral-1400);
  position: relative;
  padding-top: var(--space-20);
  text-align: center;
  width: 100%;
  margin-bottom: 2rem;
}
#vision-mention .h2::before {
  content: "";
  width: 60px;
  height: 5px;
  border-radius: 2.5px;
  background-color: var(--dash-color);
  display: inline-block;
  position: absolute;
  top: 0;
}
#vision-mention .h2::before {
  left: 50%;
  transform: translateX(-50%);
}
#vision-mention h3 {
  margin-block: 1em;
}
#vision-mention .btn-default-neg {
  --cta-bg: #fff;
  --cta-color: var(--color-blue-1100);
  --cta-bg-hover: var(--color-neutral-300);
  --cta-bg-active: #e3e6e9;
  --cta-bg-disabled: var(--color-neutral-200);
  --cta-color-disabled: #969da4;
  --cta-color-border: var(--color-neutral-600);
  --cta-color-border-disabled: var(--cta-color-disabled);
  border: 1px solid var(--cta-color-border);
}
#vision-mention img {
  width: 10%;
  display: inline-block;
}
@media (max-width: 767px) {
  #vision-mention .close-vision {
    margin-top: 1rem;
  }
}
#vision-mention .cercle-double {
  position: relative;
  display: inline-block;
  margin: 0.9rem;
  padding: 0.5rem;
}
#vision-mention .cercle-double::before, #vision-mention .cercle-double::after {
  content: "";
  position: absolute;
  border-radius: 5rem;
  border: 2px solid var(--color-brand-blue);
}
#vision-mention .cercle-double::before {
  width: 1.3rem;
  height: 1.3rem;
  top: 0.5rem;
  left: 1rem;
}
#vision-mention .cercle-double::after {
  width: 2.3rem;
  height: 2.3rem;
  top: 0;
  left: 0.5rem;
}

#vision-bandeau {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 500;
  background-color: var(--color-brand-blue);
}
#vision-bandeau .vision-content {
  margin: 0;
  padding: 1rem;
  text-align: center;
}
#vision-bandeau .vision-content p {
  color: #fff;
}
#vision-bandeau .col-2 {
  display: inline-block;
}
@media (max-width: 767px) {
  #vision-bandeau .col-2 {
    width: 100%;
  }
}
@media (max-width: 1023px) {
  #vision-bandeau .col-2:first-child {
    text-align: center;
  }
}
#vision-bandeau .col-2 .btn-secondary {
  border: none;
  top: 0.8rem;
  left: 1rem;
  margin: 0 0 0 1rem;
}
@media (max-width: 767px) {
  #vision-bandeau .col-2 .btn-secondary {
    top: 0;
    left: 0;
    width: 100%;
  }
}
#vision-bandeau #vision-bandeau .col-2 .btn-secondary {
  margin: 0 0 0 1rem;
}

.main-footer-gestion li.open_vision {
  color: var(--color-neutral-1000);
  cursor: pointer;
}
.main-footer-gestion li.open_vision:hover {
  color: var(--color-brand-blue);
}

body.client #vision-mention {
  margin-top: 0 !important;
}

div[uwtclass="com.unblu.core.client.ui.engagement.L7"] {
  display: none;
}

body:has(#vision-mention:not([hidden])) *:not(:has(#vision-mention)):not(#vision-mention):not(#vision-mention *) {
  display: none;
}
body:has(#vision-mention:not([hidden])) #vision-mention {
  display: revert !important;
}